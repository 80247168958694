import { Button, Divider } from 'antd';
import styled from 'styled-components';

export const AddBudgetButton = styled(Button)`
  align-self: flex-start;
`;

export const BudgetDetailsDivider = styled(Divider)`
  margin: 10px !important;
`;

export const BudgetContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const VariantContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const BudgetMaterial = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
`;

export const OptionMenuButton = styled(Button)`
  height: initial !important;
  padding: 8px 16px !important;
  text-align: left !important;
`;
