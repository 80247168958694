import React, { useState } from 'react';
import { changeRequestTabsIds } from '@iso/lib/constants/changeRequestTabs';
import { Collapse, Form, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CollapseStyle2 } from '@iso/assets/styles/collapse.styles';
import {
  ChangeRequestExtra,
  ChangeRequestHeader,
  renderBudgetItems,
  renderMilestoneDetails,
} from '@iso/components/ChangeRequest/ChangeRequestCollapseUtils';
import SubmitForm from '@iso/components/Proposal/SubmitForm';
import { AntFormStyles } from '@iso/assets/styles/antForm.styles';
import moment from 'moment';
import {
  setChangeRequest,
  setIsChangeRequestModalOpen,
  submitChangeRequest,
} from '@iso/redux/changeRequest/actions';
import { removeEmptyKeys } from '@iso/lib/helpers/utility';
import Notification from '@iso/components/Notification';
import { useHistory } from 'react-router-dom';
import { useWatch } from 'antd/lib/form/Form';

export default function Summary({ TabFooter }) {
  const { changeRequest } = useSelector((state) => state.ChangeRequest);
  const [form] = Form.useForm();

  const { Panel } = Collapse;
  const submitInitialValues = {
    changeRequestPrice: changeRequest.details?.changeRequestPrice ?? '',
    newMoveInDate: changeRequest.details?.newMoveInDate
      ? moment(changeRequest.details?.newMoveInDate)
      : '',
  };
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const proposalId = searchParams.get('proposalId');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const changeRequestPrice = useWatch('changeRequestPrice', form);

  function handleSubmitChangeRequest(values) {
    setIsSubmitting(true);
    const payload = {
      changeRequestId: changeRequest.details.changeRequestId,
      newMoveInDate: values.newMoveInDate
        ? moment(values.newMoveInDate).format('YYYY-MM-DD')
        : null,
      milestoneId: changeRequest.newMilestone.milestoneId,
      proposalId,
      milestoneName: changeRequest.newMilestone.milestoneName,
      description: changeRequest.newMilestone.description,
      startDate: moment(changeRequest.newMilestone.startDate).format(
        'YYYY-MM-DD',
      ),
      endDate: moment(changeRequest.newMilestone.endDate).format('YYYY-MM-DD'),
      action: 'submit',
      oldMilestoneId: changeRequest?.oldMilestone?.milestoneId,
      changeRequestPrice: values.changeRequestPrice
        ? values.changeRequestPrice
        : changeRequest?.newMilestone?.changeRequestPrice,
      tags: changeRequest?.newMilestone?.tags,
    };
    dispatch(
      submitChangeRequest({
        data: removeEmptyKeys(payload),
        successCallback: (response) => {
          setIsSubmitting(false);
          dispatch(setIsChangeRequestModalOpen(false));
          history.replace({
            pathname: window.location.pathname,
            search: '',
          });
          dispatch(
            setChangeRequest({
              details: null,
              newMilestone: null,
              oldMilestone: null,
              newMilestoneBudgets: {},
              oldMilestoneBudgets: {},
            }),
          );
          // dispatch(setActiveTabKey(changeRequestTabsIds['1']));

          Notification('success', 'Change request is successfully submitted');
        },
        errorCallback: (error) => {
          console.log('error', error);
          Notification('error', error.body.message);
          setIsSubmitting(false);
        },
      }),
    );
  }

  return (
    <>
      {changeRequest?.details && (
        <CollapseStyle2
          className="ant-collapse-style2 ant-collapse-parent"
          key={changeRequest.details.changeRequestId}
          defaultActiveKey={[changeRequest.details.changeRequestId]}
        >
          <Panel
            header={
              <ChangeRequestHeader
                milestone={changeRequest.newMilestone}
                status={changeRequest.details.status}
              />
            }
            key={changeRequest.details.changeRequestId}
            extra={
              <ChangeRequestExtra
                isEdit={false}
                changeRequestDetails={changeRequest.details}
              />
            }
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {renderMilestoneDetails(
                changeRequest.newMilestone,
                'New Milestone Details',
              )}
              {Object.values(changeRequest.newMilestoneBudgets).length &&
                renderBudgetItems(
                  Object.values(changeRequest.newMilestoneBudgets),
                )}
              {changeRequest.oldMilestone && (
                <>
                  {renderMilestoneDetails(
                    changeRequest.oldMilestone,
                    'Old Milestone Details',
                  )}
                  {Object.values(changeRequest.oldMilestoneBudgets) &&
                    renderBudgetItems(
                      Object.values(changeRequest.oldMilestoneBudgets),
                    )}
                </>
              )}
            </Space>
          </Panel>
        </CollapseStyle2>
      )}
      <AntFormStyles style={{ marginTop: '20px' }}>
        <SubmitForm
          layout="vertical"
          onFinish={handleSubmitChangeRequest}
          form={form}
          initialValues={{ ...submitInitialValues }}
        />
      </AntFormStyles>
      <TabFooter
        loading={isSubmitting}
        handleSubmit={() => form.submit()}
        previousTab={changeRequestTabsIds['2']}
        disablePrimaryAction={!changeRequestPrice}
      />
    </>
  );
}
