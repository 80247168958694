import AddMilestone from '@iso/containers/ChangeRequest/_components/AddMilestone';
import AddBudget from '@iso/containers/ChangeRequest/_components/AddBudget';
import Summary from '@iso/containers/ChangeRequest/_components/Summary';

const changeRequestTabsIds = {
  1: 'milestone',
  2: 'budget',
  3: 'summary',
};
const changeRequestTabs = [
  {
    id: changeRequestTabsIds['1'],
    label: 'Milestone',
    children: AddMilestone,
  },
  {
    id: changeRequestTabsIds['2'],
    label: 'Budget',
    children: AddBudget,
  },
  {
    id: changeRequestTabsIds['3'],
    label: 'Summary',
    children: Summary,
  },
];
/*
* DRAFT,
AWAITING_CONTRACTOR_CONFIRMATION,
AWAITING_HOME_OWNER_CONFIRMATION,
AWAITING_ADMIN_CONFIRMATION,
REJECTED_BY_HOME_OWNER,
APPROVED_BY_HOME_OWNER,
COMPLETED, // this is logical status
ONGOING // this is logical status
* */
const changeRequestStatuses = {
  DRAFT: {
    label: 'Draft',
    color: 'default',
    value: 'DRAFT',
  },
  ONGOING: {
    label: 'Ongoing',
    color: 'blue',
    value: 'ONGOING',
  },
  COMPLETED: {
    label: 'Completed',
    color: 'green',
    value: 'COMPLETED',
  },
  AWAITING_CONTRACTOR_CONFIRMATION: {
    label: 'Awaiting contractor confirmation',
    color: 'blue',
    value: 'AWAITING_CONTRACTOR_CONFIRMATION',
  },
  AWAITING_HOME_OWNER_CONFIRMATION: {
    label: 'Awating homeowner confirmation',
    color: 'blue',
    value: 'AWAITING_HOME_OWNER_CONFIRMATION',
  },
  AWAITING_ADMIN_CONFIRMATION: {
    label: 'Awaiting admin confirmation',
    color: 'blue',
    value: 'AWAITING_ADMIN_CONFIRMATION',
  },
  REJECTED_BY_HOME_OWNER: {
    label: 'Rejected by homeowner',
    color: 'red',
    value: 'REJECTED_BY_HOME_OWNER',
  },
  APPROVED_BY_HOME_OWNER: {
    label: 'Approved by homeowner',
    color: 'green',
    value: 'APPROVED_BY_HOME_OWNER',
  },
};

export { changeRequestTabs, changeRequestTabsIds, changeRequestStatuses };
